<template>
    <div>
        <v-dialog ref="the_dialog" :value="value" @input="handlePopup" width="90%">
            <v-card class="text-center">
                <QTabs dark v-model="tab">
                    <v-tab>Agent Configs</v-tab>
                    <v-tab>User Configs</v-tab>
                    <v-tab @click="handleNewConfig">
                        <span v-if="config.ID">Edit Config</span>
                        <span v-else>Add New Config</span>
                    </v-tab>
                    <v-tab>Help</v-tab>
                    <v-tab @click="handlePopup(false)">Close
                        <v-icon right>fas fa-times</v-icon>
                    </v-tab>
                </QTabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item class="pa-6">
                        <h4 class="font-weight-bold">
                            Agent Configs
                        </h4>
                        <p class="text-left">These are "application" configurations applied to anyone signed into the agent's account. They are used and set primarily on the app side and are not something set or changeable by the user.</p>
                        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                        <v-simple-table dense height="250" v-if="agent_config">
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="(item, key) in agent_config" :key="item.ConfigName">
                                        <td class="text-left"><span class="truncate">{{ item.ConfigName }}</span></td>
                                        <td class="text-left"><span class="truncate">{{ item.DataType == "boolean" ? (item.ConfigValue === true || item.ConfigValue == "1" ? "true" : "false") : item.ConfigValue}}</span></td>
                                        <td class="text-left"><span class="truncate">{{ item.LastChangeBy }}</span></td>
                                        <td class="text-left"><span class="truncate">{{ item.LastChangeDate }}</span></td>
                                        <td><span class="truncate">
                                                <v-btn icon @click="editConfig(item)">
                                                    <v-icon>fas fa-edit</v-icon>
                                                </v-btn>
                                                <v-btn icon color="red" @click="deletePermission(item.ID)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-tab-item>
                    <v-tab-item class="pa-6">
                        <h4 class="font-weight-bold">
                            User Configs
                        </h4>
                        <p class="text-left">These are configurations applied to the user who is signed in. They are used to store preferences and custom configurations for the user's experience.</p>
                        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                        <v-simple-table dense height="250" v-if="user_config">
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="(item, key) in user_config" :key="item.ConfigName">
                                        <td class="text-left"><span class="truncate">{{ item.ConfigName }}</span></td>
                                        <td class="text-left"><span class="truncate">{{ item.DataType == "boolean" ? (item.ConfigValue ? "true" : "false") : item.ConfigValue}}</span></td>
                                        <td class="text-left"><span class="truncate">{{ item.LastChangeBy }}</span></td>
                                        <td class="text-left"><span class="truncate">{{ item.LastChangeDate }}</span></td>
                                        <td><span class="truncate">
                                                <v-btn icon @click="editConfig(item)">
                                                    <v-icon>fas fa-edit</v-icon>
                                                </v-btn>
                                                <v-btn icon color="red" @click="deletePermission(item.ID)">
                                                    <v-icon>fas fa-times-circle</v-icon>
                                                </v-btn>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-else>This agent has not signed in yet, so a user config cannot be created yet.</div>
                    </v-tab-item>
                    <v-tab-item class="pa-6">
                        <h4 class="font-weight-bold" style="position:relative">
                            <span v-if="config.ID">Edit Config</span>
                            <span v-else>Add New Config</span>
                        </h4>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row wrap>
                                <v-col cols="6">
                                    <v-text-field label="Config Key" v-model="config.ConfigName" :rules="[form_rules.required]"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-if="!config.ID" :items="config_types" label="Type" v-model="config_type"></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select :items="available_types" label="Data Type" v-model="config.DataType"></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field label="Config Value" v-if="config.DataType == 'int'" :rules="[form_rules.required,form_rules.int]" v-model="config.ConfigValue"></v-text-field>
                                    <v-text-field label="Config Value" v-if="config.DataType == 'decimal'" :rules="[form_rules.required,form_rules.number]" v-model="config.ConfigValue"></v-text-field>
                                    <v-text-field label="Config Value" v-if="config.DataType == 'string'" :rules="[form_rules.required]" v-model="config.ConfigValue"></v-text-field>
                                    <v-switch v-model="config.ConfigValue" v-if="config.DataType == 'boolean'" :label="`Value : ${config.ConfigValue.toString()}`"></v-switch>
                                    <v-textarea class="monospace" label="Config Value" v-if="config.DataType == 'json'" :rules="[form_rules.required, form_rules.json]" v-model="config.ConfigValue">
                                    </v-textarea>
                                </v-col>
                                <v-col cols="12" class="test-center">
                                    <v-btn @click="cancel" class="mr-2">Cancel</v-btn>
                                    <v-btn color="primary" @click="updateConfig" :disabled="!valid && !loading">Save Config</v-btn>
                                    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                    <v-tab-item class="pa-6 text-left">
                        <h4 class="font-weight-bold" style="position:relative">Helpful Info</h4>
                        <p>You can add any agent config you want. Below is a list of keys and definitions for ones we currently use.</p>
                        <ul>
                            <li>
                                <strong>AcceptedSoftwareAgreement Beta X.X</strong> - Used for storing software agreement acceptance.
                            </li>
                            <li>
                                <strong>UseDBAForOCDTC</strong> - true/false - Use the agent's DBA and NPN DBA when submitting applications in the DTC application.
                            </li>
                            <li>
                                <strong>AgentSignatureImage</strong> - Used for storing the agent's signature. Required for Sureify and OC Sites.
                            </li>
                            <li>
                                <strong>lightSpeedFastTrackCompleteDate</strong> - Date they completed fast track (a summit access requirement).
                            </li>
                            <li>
                                <strong>DemosSeen</strong> - JSON - Used for storing what demos the agent doesn't want to see anymore.
                            </li>
                            <li>
                                <strong>HideMiddlename</strong> - Hides Middle Name in HQ.
                            </li>
                            <li>
                                <strong>QuilityRXAgreement</strong> - true/false - Date they accepted the agreement.
                            </li>
                            <li>
                                <strong>HideDBA</strong> - true/false - Hide's their Doing Businerss As information in HQ even if it's filled out.
                            </li>
                            <li>
                                <strong>SwitchboardConfig</strong> - JSON - Used for storing Agent's location id for syncing switchboard.
                            </li>
                            <li>
                                <strong>HasOCDTCSubscription</strong> - true/false - If true, they are allowed to use the DTC application at apply.quility.com. If false or null... redirect's their application to Quility.
                            </li>
                            <li>
                                <strong>lightSpeedUserCourseData</strong> - Store's the agent's course completion data from LightSpeed/QuilityU.
                            </li>
                            <li>
                                <strong>FirstCommissionAccountingCycle</strong> - ??
                            </li>
                            <li>
                                <strong>AppointmentCalendarURL</strong> - Used for DFL to store where the agent's calendar scheduler is.
                            </li>
                            <li>
                                <strong>glic_rx_8_card_layout_url</strong> - Used to stored the agent's printable QuilityRx cards.
                            </li>
                            <li>
                                <strong>LeadOrderPrivilegeRevoked</strong> - true/false - If true, the agent cannot use the lead order system.
                            </li>
                        </ul>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import moment from 'moment'
import QTabs from '../navigation/QTabs.vue'

export default {
    name: 'QEditAgentConfig',
    props: ['value', 'agent', 'agentLevelId'],
    data() {
        return {
            permissions: null,
            loading: false,
            agent_config: null,
            user_config: null,
            config: { DataType: 'string', ConfigValue: '' },
            config_type: 'Agent Config',
            tab: 0,
            config_user: null,
            available_types: [
                'boolean',
                'string',
                'int',
                'decimal',
                'json'
            ],
            valid: false
        }
    },
    mounted: function() {
        this.handleNewConfig()
    },
    methods: {
        handlePopup(e) {
            this.tab = 0;
            this.handleNewConfig();
            this.$emit('input', e)
        },
        handleNewConfig() {
            this.config = { DataType: 'string', ConfigValue: '' }
        },
        reset() {
            this.handleNewConfig();
            this.loadConfigs();
        },
        cancel() {
            this.tab = 0;
            this.handleNewConfig();
        },
        loadConfigs: function() {
            var g = this;
            g.loading = true;
            QuilityAPI.getAgentConfigs(this.agent.AgentCode).then(function(json) {
                g.$set(g, 'agent_config', json.data.AgentConfig)
                g.$set(g, 'user_config', json.data.UserConfig)
                g.$set(g, 'config_user', json.meta.user)
                g.loading = false;
            }, function(err) {
                if (err != 'Aborted') {
                    g.showError(err);
                }
                g.loading = false;
            }).catch(function(err) {
                g.loading = false;
            })
        },
        updateConfig: function() {
            this.$refs.form.validate()
            if (this.valid) {
                var g = this;
                g.loading = true;
                if (this.config.ID) {
                    var api_call = QuilityAPI.updateAgentConfig(this.agent.AgentCode, this.config)
                    if (this.config.AgentID) {
                        this.config_type = "Agent Config"
                    } else {
                        this.config_type = "User Config"
                    }
                } else {
                    api_call = QuilityAPI.addAgentConfig(this.agent.AgentCode, this.config, this.config_type)
                }
                api_call.then(function(json) {
                    g.$set(g, 'agent_config', json.data.AgentConfig)
                    g.$set(g, 'user_config', json.data.UserConfig)
                    g.$set(g, 'config_user', json.meta.user)
                    g.handleNewConfig();
                    g.loading = false;
                    if (g.config_type == 'Agent Config') {
                        g.tab = 0
                    } else {
                        g.tab = 1
                    }
                }, function(err) {
                    g.showError(err);
                    g.loading = false;
                }).catch(function(err) {
                    g.loading = false;
                })
            }
        },
        deletePermission: function(config_id) {
            var g = this;
            g.loading = true;
            QuilityAPI.deleteAgentConfig(this.agent.AgentCode, config_id).then(function(json) {
                g.$set(g, 'agent_config', json.data.AgentConfig)
                g.$set(g, 'user_config', json.data.UserConfig)
                g.$set(g, 'config_user', json.meta.user)
                g.handleNewConfig();
                g.loading = false;
            }, function(err) {
                g.showError(err?.msg || err);
                g.loading = false;
            }).catch(function(err) {
                g.loading = false;
            })
        },
        editConfig: function(config) {
            console.log(config)
            this.config = config;
            this.tab = 2
        }
    },
    computed: {
        'agent_permissions': function() {
            var r = [];
            this.permission_choices.forEach(function(i) {
                if (i.indexOf('agent:') == 0) {
                    r.push(i)
                }
            })
            return r;
        },
        'config_types': function() {
            if (this.config_user) {
                return ['Agent Config', 'User Config']
            }
            return ['Agent Config']
        }
    },
    watch: {
        'value': function(newV, oldV) {
            var g = this
            this.$nextTick(function() {
                g.tab = 0;
            })
            if (newV === false) {
                this.$emit('input', false)
            } else {
                this.reset();
            }
        },
        'config.DataType': function(newV, oldV) {
            var g = this
            console.log(g.config.DataType, g.config.ConfigValue)
            this.$nextTick(function() {
                switch (g.config.DataType) {
                    case 'boolean':
                        g.config.ConfigValue = g.config.ConfigValue ? (g.config.ConfigValue === true || g.config.ConfigValue == "1" ? true : false) : true;
                        break;
                    case 'string':
                        g.config.ConfigValue = g.config.ConfigValue ? String(g.config.ConfigValue) : "";
                        break;
                    case 'int':
                        g.config.ConfigValue = g.config.ConfigValue ? parseInteger(g.config.ConfigValue) : 1;
                        break;
                    case 'decimal':
                        g.config.ConfigValue = g.config.ConfigValue ? parseFloat(g.config.ConfigValue) : "1.0";
                        break;
                    case 'json':
                        console.log(typeof g.config.ConfigValue)
                        if (typeof g.config.ConfigValue == "object") {
                            g.config.ConfigValue = g.config.ConfigValue ? JSON.stringify(g.config.ConfigValue) : "{}";
                        } else {
                            g.config.ConfigValue = g.config.ConfigValue ? g.config.ConfigValue : "{}";
                        }
                        break;
                }
            })

        }
    },
    components: {
        QTabs,
    }
}
</script>